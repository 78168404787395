import { QueryParams } from "../utils/CommonUtils/constants";

const useParamValues = () => {
  const urlParams = new URLSearchParams(window.location.search);

  let organisation = urlParams.get(QueryParams.ORGNISATION);
  let appLanguage = urlParams.get(QueryParams.APP_LANGUAGE);

  return {
    organisation:
      organisation === "" || organisation === null || organisation === undefined
        ? ""
        : organisation,
    appLanguage:
      appLanguage === "" || appLanguage === null || appLanguage === undefined
        ? "en"
        // : appLanguage,
        : "en",
  };
};

export default useParamValues;
