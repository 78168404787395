import React from "react";
import "./VopaInstructionScreen.css";
import VopaInstructionComponent from "../../Components/VopaInstructionComponent/VopaInstructionComponent";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import MobileStepper from "@mui/material/MobileStepper";
import { useState } from "react";
import { Button } from "@mui/material";
import CardsGroupComponent from "../../Components/CardsGroupComponent/CardsGroupComponent";
import MicGif from "../../Components/GroupedAssets/MicGif/MicGif";
import StudentImgWithHandMobileGif from "../../Components/GroupedAssets/StudentImgWithHandMobileGif/StudentImgWithHandMobileGif";
import AudioMovingGif from "../../Components/GroupedAssets/AudioMovingGif/AudioMovingGif";
import TimerGif from "../../Components/GroupedAssets/TimerGif/TimerGif";
import { useTranslationSetup } from "../../utils/useTranslationSetup.js";
import useParamValues from "../../utils/useParamValues.js";

const VopaInstructionScreen = ({
  showVopaInstructionScreen,
  setShowVopaInstructionScreen,
  showVopaInputFieldsScreen,
  setShowVopaInputFieldsScreen,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslationSetup();
  const { appLanguage } = useParamValues();

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const vopaInstructionContent = [
    {
      heading: t("working_mic"),
      content: t("working_mic_description"),
      gif: <MicGif />,
    },
    {
      heading: t("device_proximity"),
      content: t("device_proximity_description"),
      gif: <StudentImgWithHandMobileGif />,
    },
    {
      heading: t("avoid_noise"),
      content: t("avoid_noise_description"),
      gif: <AudioMovingGif />,
    },
    {
      heading: t("finish_the_paragraph"),
      content: t("finish_the_paragraph_description"),
      gif: <TimerGif />,
    },
  ];

  return (
    <div>
      <div className="vopa-instructions-screen-heading">
        {t("assessment_time")}
      </div>
      <div className="vopa-instructions-screen-desc">
        {t("ensure_the_following_when_conducting_an_assessment")}
      </div>
      <div
        style={{
          margin: "0 12% 0",
        }}
      >
        <CardsGroupComponent>
          <SwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            style={{
              margin: "0 0 30px",
            }}
          >
            {vopaInstructionContent.map((item, index) => (
              <div key={index}>
                <VopaInstructionComponent
                  heading={item.heading}
                  content={item.content}
                  gif={item.gif}
                />
              </div>
            ))}
          </SwipeableViews>

          <MobileStepper
            steps={4}
            position="static"
            activeStep={activeStep}
            nextButton={
              <div className="stepper-next-button">
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === vopaInstructionContent.length - 1}
                  variant="contained"
                  disableElevation
                  style={{
                    borderRadius: "6px",
                    color: "#FFF",
                    fontFamily: "Poppins",
                    fontSize: appLanguage === "en" ? "12px" : "13px",
                    fontWeight: "400",
                    backgroundColor:
                      activeStep !== vopaInstructionContent.length - 1 &&
                      "#E47714",
                  }}
                >
                  {t("next")}
                </Button>
              </div>
            }
            backButton={
              <div className="stepper-back-button">
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: appLanguage === "en" ? "12px" : "13px",
                    fontWeight: "400",
                    padding: "0px 0px",
                    color: activeStep !== 0 && "#E47714",
                  }}
                >
                  {t("back")}
                </Button>
              </div>
            }
          />
        </CardsGroupComponent>
      </div>
      {activeStep !== vopaInstructionContent.length - 1 && (
        <div className="seen-instructions-before-text">
          {t("have_seen_instructions_before")}
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          margin: "0 0 10%",
        }}
      >
        <Button
          variant={
            activeStep < vopaInstructionContent.length - 1
              ? "outlined"
              : "contained"
          }
          style={{
            height: "46px",
            width: "65%",
            textTransform: "none",
            color:
              activeStep < vopaInstructionContent.length - 1
                ? "#E47714"
                : "#FFF",
            fontWeight: "400",
            fontSize: "16px",
            fontFamily: "Poppins",
            borderRadius: "12px",
            border: "1px solid #E47714",
            background:
              activeStep < vopaInstructionContent.length - 1 ? "" : "#E47714",
            margin: activeStep === vopaInstructionContent.length - 1 && "11vh 0 0",
          }}
          disableElevation
          onClick={() => {
            setShowVopaInstructionScreen(false);
            setShowVopaInputFieldsScreen(true);
          }}
        >
          {activeStep < vopaInstructionContent.length - 1
            ? t("skip")
            : t("continue")}
        </Button>
      </div>
    </div>
  );
};

export default VopaInstructionScreen;
