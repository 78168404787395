import React from "react";
import "./VopaInstructionComponent.css";

const VopaInstructionComponent = ({ heading, content, gif }) => {
  return (
    <div>
      <div>
        {gif}
      </div>
      <div className="vopa-instruction-heading">{heading}</div>
      <div className="vopa-instruction-desc">
        {content}
        {heading === "4. Finish the Paragraph" ? <span className="vopa-instruction-desc-span"> and read it aloud.</span> : null}
      </div>
    </div>
  );
};

export default VopaInstructionComponent;
