import { useState, useRef } from "react";
import { useEffect } from "react";

const useAudioRecorderV2 = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isResumed, setIsResumed] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [error, setError] = useState(null);
  const [duration, setDuration] = useState(null);
  const [extension, setExtension] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
      setExtension("webm;codecs=opus");
    } else {
      setExtension("ogg");
    }
  }, []);

  const handleStartRecording = () => {
    setError(null);
    audioChunksRef.current = [];

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          channels: 1,
          autoGainControl: false,
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 48000,
        },
      })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/" + extension,
            bitsPerSecond: 128000,
            //   audioBitsPerSecond: 256000,
          });
          setAudioBlob(audioBlob);
          extractDuration(audioBlob);
          setIsRecording(false);
          setIsStopped(true);
        };

        setTimeout(() => {
          mediaRecorder.start();
        }, 800);
        setIsRecording(true);
        setIsStopped(false);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setError("Error accessing microphone: ", error);
      });
  };

  const handleStopRecording = () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      audioChunksRef.current = [];
    }
  };

  const handlePauseRecording = () => {
    if (mediaRecorderRef.current && isRecording && !isPaused) {
      mediaRecorderRef.current.pause();
      setIsRecording(false);
      setIsPaused(true);
    }
  };

  const handleResumeRecording = () => {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsRecording(true);
      setIsPaused(false);
    }
  };

  const extractDuration = (audioBlob) => {
    if (audioBlob) {
      const audioElement = new Audio(audioBlob);
      audioElement.addEventListener("loadedmetadata", (e) => {
        console.log(e.target.duration);
      });
      audioElement.onloadedmetadata = () => {
        setDuration(audioElement.currentTime);
      };
    }
  };

  return {
    isRecording: isRecording,
    isStopped: isStopped,
    isPaused: isPaused,
    isResumed: isResumed,
    audioBlob: audioBlob,
    recordingError: error,
    duration: duration,
    setAudioBlob: setAudioBlob,
    startRecording: handleStartRecording,
    stopRecording: handleStopRecording,
    pauseRecording: handlePauseRecording,
    resumeRecording: handleResumeRecording,
  };
};
export default useAudioRecorderV2;