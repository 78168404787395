
export const getTeacherId = (organisation) => {
  if (organisation === "bihar_pilot_1" || organisation === "bihar_demo_1") {
    return "teacher_bihar_demo";
  } else if (organisation === "demo" || organisation === "generic_demo") {
    return "teacher_generic_demo";
  } else if (organisation === "pratham_demo_1") {
    return "teacher_pratham_demo";
  } else {
    return "teacher_generic_demo";
  }
};

export const getOrganisationId = (organisation) => {
  if (organisation === "demo") {
    return "generic_demo";
  } else if (organisation === "pratham_demo_1") {
    return "pratham_demo_1";
  } else if (
    organisation === "bihar_demo_1" ||
    organisation === "bihar_pilot_1"
  ) {
    return "bihar_pilot_1";
  } else {
    return "generic_demo";
  }
};

export const getDeviceId = (organisation) => {
  if (organisation === "bihar_pilot_1" || organisation === "bihar_demo_1") {
    return "device_bihar_demo";
  } else if (organisation === "pratham_demo_1") {
    return "device_pratham_demo";
  } else if (organisation === "demo" || organisation === "generic_demo") {
    return "device_generic_demo";
  } else {
    return "device_generic_demo";
  }
};

const getOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return options;
};

export const getClassNumOptions = (organisation) => {
  if (
    organisation === "bihar_pilot_1" ||
    organisation === "bihar_demo_1" ||
    organisation === "pratham_demo_1"
  ) {
    return getOptions(1, 8);
  } else {
    return getOptions(3, 3);
  }
};

export const getNameLabel = (organisation) => {
  if (organisation === "pratham_demo_1") {
    return "Child name/id";
  } else {
    return "My name is";
  }
};

export const getGradeLabel = (organisation) => {
  if (organisation === "pratham_demo_1") {
    return "Grade";
  } else {
    return "Grade for assessment";
  }
};

export const getSchoolIdLabel = (organisation) => {
  if (
    organisation === "bihar_pilot_1" ||
    organisation === "bihar_demo_1" ||
    organisation === "pratham_demo_1"
  ) {
    return "School Id";
  } else {
    return "My institution is";
  }
};


export const getGrade=(organisation,grade)=>{
  if (
    organisation === "bihar_pilot_1" ||
    organisation === "bihar_demo_1" 
  ) {
    return grade;
  } else {
    return 3;
  }
}

export const getTextFieldType=(organisation)=>{
  if (
    organisation === "bihar_pilot_1" ||
    organisation === "bihar_demo_1" ||
    organisation === "pratham_demo_1"
  ) {
    return "number";
  } else {
    return "text";
  }
}