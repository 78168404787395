import React from "react";
import "./AudioMovingGif.css";
import audioMovingJson from "../../../assets/LottieFiles/audio_moving.json";
import Lottie from "react-lottie-player";

const AudioMovingGif = () => {
  return (
    <div>
      <Lottie
        loop
        animationData={audioMovingJson}
        play
        style={{
          width: "100%",
          height: "224px",
          margin: "10px 0 0",
          filter:
            "drop-shadow(0px 22px 29px rgba(125, 138, 255, 0.38))",
        }}
      />
    </div>
  );
};

export default AudioMovingGif;
