import React, { useState } from "react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import "./CustomSelectorChip.css";

const CustomSelectorChip = ({ setValue, options }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (index) => {
    setSelectedOption(index);
    setValue(options[index]);
  };

  return (
    <>
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            handleOptionSelect(index);
          }}
          className={`custom-selector-chip-container ${option.value} ${
            selectedOption === index && "active"
          }`}
        >
          <div
            style={{
              display: "flex",
              gap: "30px",
            }}
          >
            <div className="abbreviation-text">{option.abbreviation}</div>
            <div className="label-text">{option.label}</div>
          </div>
          <div
            className={`custom-selector-checkbox-container ${
              selectedOption === index && option.value
            }`}
          >
            {selectedOption === index && (
              <DoneOutlinedIcon
                className={`custom-selector-checkbox ${option.value}`}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default CustomSelectorChip;
