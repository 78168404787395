import React, { useState } from "react";
import { Button } from "@mui/material";
import CustomCard from "./Components/CustomCard/CustomCard";
import AudioPlayer from "./AudioPlayer";
import CustomExpandableCard from "./Components/CustomExpandableCard/CustomExpandableCard";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AdditionalFeedbackDialog from "./Components/AdditionalFeedbackDialog/AdditionalFeedbackDialog";
import Header from "./Header";
import useParamValues from "./utils/useParamValues";
import CustomCircleCard from "./Components/CustomCircleCard/CustomCircleCard";
import { useTranslationSetup } from "./utils/useTranslationSetup.js";

const OrfResultScreen = ({
  heading,
  lastCwpm,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  processedData,
  showOrfResultScreen,
  setShowOrfResultScreen,
  showHomeScreen,
  setShowHomeScreen,
  hideSlider,
  setHideSlider,
  studentName,
  setStudentName,
  selectedLaguage,
  setSelectedLaguage,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  saveClicked,
  setSaveClicked,
  isAudioUploading,
  setAudioUploading,
  uploadAudioStatus,
  setUploadAudioStatus,
}) => {
  const [showAdditionalFeedbackDialog, setShowAdditionalFeedbackDialog] =
    useState(false);
  const { organisation } = useParamValues();
  const [feedbackList, setFeedbackList] = useState([]);
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(false);
  const [individualInputFeedback, setIndividualInputFeedback] = useState("");
  const [individualCheckboxValues, setIndividualCheckboxValues] = useState([]);
  const { t } = useTranslationSetup();

  const wordsType = [
    t("incorrect_words"),
    t("missed_words"),
    t("correct_words"),
    t("extra_words"),
  ];

  const wordsTypeWordList = [
    processedData["incorrect_words"],
    processedData["missed_words"],
    processedData["correct_words"],
    processedData["extra_words"],
  ];

  return (
    <>
      <div>
        <AdditionalFeedbackDialog
          isDialogOpen={showAdditionalFeedbackDialog}
          setDialogOpen={setShowAdditionalFeedbackDialog}
          isPositiveFeedback={isPositiveFeedback}
          setIsPositiveFeedback={setIsPositiveFeedback}
          individualInputFeedback={individualInputFeedback}
          setIndividualInputFeedback={setIndividualInputFeedback}
          individualCheckboxValues={individualCheckboxValues}
          setIndividualCheckboxValues={setIndividualCheckboxValues}
        />
      </div>
      <Header
        background="linear-gradient(134.56deg, #1E5CD9 59.55%, #76BDF5 152.08%)"
        hideArrow="true"
        heading={heading}
        showShareDownloadIcon={false}
      />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Inter",
            marginTop: "7px",
            marginBottom: "7px",
          }}
        >
          {t("correct_words_per_minute")}
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Open Sans",
            color: "#000",
            letterSpacing: "-0.304px",
          }}
        >
          {t("cwpm")}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "24px",
          }}
        >
          <CustomCircleCard>
            <div
              style={{
                verticalAlign: "center",
                fontWeight: "400",
                color: "#000000",
                fontFamily: "Open Sans"
              }}
            >
              <p
                style={{
                  fontSize: "33px",
                  fontFamily: "Open Sans",
                }}
              >
                {Math.round(processedData["cwpm"])}
              </p>
            </div>
          </CustomCircleCard>
        </div>
        <CustomCard>
          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              padding: "12px 19px 12px 19px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {t("total_words")}
              </div>
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {processedData["total_words"]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {t("total_words_read")}
              </div>
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {processedData["total_words_read"]}
              </div>
            </div>
          </div>
        </CustomCard>
        {lastCwpm > 0 && (
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Inter",
              color: "#373737",
              marginTop: "13px",
              marginLeft: "6%",
            }}
          >
            {t("cwpm_last_assessment")} - {lastCwpm}
          </div>
        )}
        <div
          style={{
            marginTop: "40px",
          }}
        >
          <CustomCard>
            <div
              style={{
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "450",
                  fontFamily: "Open Sans",
                  color: "#000000",
                  marginTop: "8px",
                  marginLeft: "13px",
                  marginBottom: "11px",
                }}
              >
                {t("orf_test_recording")}
              </div>
              <div
                style={{
                  paddingBottom: "5px",
                }}
              >
                <AudioPlayer
                  audioBlobForAudioPlayer={audioBlobForAudioPlayer}
                  setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
                />
              </div>
            </div>
          </CustomCard>
        </div>
        <div
          style={{
            marginTop: "42px",
          }}
        >
          {wordsType.map((wordType, index) => (
            <div
              key={wordType}
              style={{
                marginBottom: "24px",
              }}
            >
              <CustomExpandableCard
                header={wordType}
                wordCount={wordsTypeWordList[index].length}
                wordsList={wordsTypeWordList[index]}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            startIcon={<KeyboardReturnIcon />}
            style={{
              width: "90%",
              textTransform: "none",
              borderRadius: "12px",
              color: "#fff",
              fontWeight: "400",
              fontSize: "20px",
              margin: "10px 0px 20px 0px",
            }}
            onClick={() => {
              setShowOrfResultScreen(false);
              setShowHomeScreen(true);
              setHideSlider(false);
              setUploadAudioStatus(null);
              setAudioUploading(false);
              setSaveClicked(false);
              setStudentName("");
              setSelectedLaguage("");
              if (organisation === "vopa") {
                setClassNum("");
                setSchoolUdise("");
              }
            }}
          >
            {t("next_student")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrfResultScreen;
