import React from "react";
import WelcomeScreenImg from "../../assets/demo_welcome_screen_image.svg";
import "./VopaWelcomeScreen.css";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useTranslationSetup } from "../../utils/useTranslationSetup.js";
// import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudTop from "../../assets/cloud-top.svg";
import CloudBottom from "../../assets/cloudBottom.svg";

const VopaWelcomeScreen = ({
  showVopaWelcomeScreen,
  setShowVopaWelcomeScreen,
  showNeedPermissionsScreen,
  setShowNeedPermissionsScreen,
  showVopaAboutScreen,
  setShowVopaAboutScreen,
  audioPermissionStatus,
  setAudioPermissionStatus,
  locationPermissionStatus,
  setLocationPermissionStatus,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  showVopaInstructionScreen,
  setShowVopaInstructionScreen,
}) => {
  const { t } = useTranslationSetup();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (locationPermissionStatus === "GRANTED" && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationPermissionStatus("GRANTED");
        },
        () => {
          setLocationPermissionStatus("DENIED");
        }
      );
    } else {
      setLocationPermissionStatus("PROMPT");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPermissionStatus]);

  function handleNavigateToNeedPermissionsScreen() {
    setShowVopaWelcomeScreen(false);
    setShowNeedPermissionsScreen(true);
  }

  function handleNavigateToVopaAboutScreen() {
    setShowVopaWelcomeScreen(false);
    // setShowVopaAboutScreen(true);
    setShowVopaInstructionScreen(true);
  }

  // eslint-disable-next-line no-unused-vars
  function toggleLanguage() {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "mr" : "en";
    sessionStorage.setItem("app_language", newLanguage);
    i18n.changeLanguage(sessionStorage.getItem("app_language"));
  }

  return (
    <div>
      <div className="cloudTop">
        <img src={CloudTop} alt="Clouds"/>
      </div>
      <div
        style={{
          textAlign: "center",
          margin: "25% 0 0 0",
        }}
      >
        <img
          style={{
            height: "33.75vh",
            width: "80vw",
          }}
          src={WelcomeScreenImg}
          alt="vopa_welcome_screen_img"
        />
      </div>
      <div className="vopa-welcome-screen-container">
        <div className="vopa-welcome-screen-heading-text">
          {t("welcome_to_orf")}
        </div>
        <div className="vopa-welcome-screen-content-wrapper">
          <div className="vopa-welcome-screen-content">
            {t("orf_description")}
          </div>
          <div className="vopa-welcome-screen-content">
            {t("orf_description_con")}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={{
              height: "46px",
              width: "100%",
              textTransform: "none",
              borderRadius: "10px",
              color: "#fff",
              fontWeight: "500",
              fontSize: "16px",
              fontFamily: "Poppins",
              background: "#E47714"
            }}
            disableElevation
            onClick={() => {
              if (
                audioPermissionStatus === "GRANTED" &&
                locationPermissionStatus === "GRANTED"
              ) {
                handleNavigateToVopaAboutScreen();
              } else {
                handleNavigateToNeedPermissionsScreen();
              }
              // handleNavigateToVopaAboutScreen();
            }}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      {//To be used later when we have more language support
      /* <div
        style={{
          textAlign: "center",
        }}
      >
        <div
          className="language-change-container"
          onClick={() => {
            toggleLanguage();
          }}
        >
          <div>
            <Trans i18nKey="language_change_text" />
            <LanguageIcon
              sx={{
                height: "16px",
                width: "16px",
                margin:
                  i18n.language === "mr" ? "0 0 -3.5px 4px" : "0 0 -2.8px 4px",
              }}
            />
          </div>
        </div>
      </div> */}
      <div className="cloudsBottom">
        <img src={CloudBottom} alt="cloud"/>
      </div>
    </div>
  );
};

export default VopaWelcomeScreen;
