import React, { useEffect, useState } from "react";
import CustomTextField from "../../Components/CustomTextField/CustomTextField";
import CustomDropDown from "../../Components/CustomDropDown/CustomDropDown";
import "./VopaInputFieldsScreen.css";
import { Button } from "@mui/material";
import { useTranslationSetup } from "../../utils/useTranslationSetup.js";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useParamValues from "../../utils/useParamValues.js";
import {
  getClassNumOptions,
  getGradeLabel,
  getNameLabel,
  getSchoolIdLabel,
  getTextFieldType,
} from "../../utils/helper.js";

const VopaInputFieldsScreen = ({
  name,
  setName,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  medium,
  setMedium,
  profession,
  setProfession,
  showVopaInputFieldsScreen,
  setShowVopaInputFieldsScreen,
  setShowAssessmentLangScreen,
  setStudentId,
  gender,
  setGender,
  age,
  setAge,
  instructorName,
  setInstructorName,
  phoneNumber,
  setPhoneNumber,
  readingLevel,
  setReadingLevel,
  setRequestBody,
  fieldsToShow,
}) => {
  const { t } = useTranslationSetup();
  const [isDisabled, setIsDisabled] = useState(true);
  const { organisation } = useParamValues();

  const onClickNext = () => {
    setStudentId(
      `${
        profession === "student" ? "ST" : profession === "teacher" ? "TE" : "OT"
      }D${new Date().getTime()}`
    );
    const feedbackDetails = {
      name,
      classNum,
      schoolUdise,
      gender,
      age,
      instructorName,
      phoneNumber,
      readingLevel,
    };

    setRequestBody(feedbackDetails);
    setShowVopaInputFieldsScreen(false);
    setShowAssessmentLangScreen(true);
  };

  // useEffect(() => {
  //   if (
  //     name !== "" &&
  //     classNum !== "" &&
  //     schoolUdise !== "" &&
  //     gender !== "" &&
  //     age !== "" &&
  //     instructorName !== "" &&
  //     phoneNumber !== "" &&
  //     readingLevel !== ""
  //     //1--   update the state according to the org id for isdisabled state
  //   ) {
  //     setIsDisabled(false);
  //   } else {
  //     setIsDisabled(true);
  //   }
  // }, [
  //   name,
  //   classNum,
  //   schoolUdise,
  //   gender,
  //   age,
  //   instructorName,
  //   phoneNumber,
  //   readingLevel,
  // ]);

  useEffect(() => {
    let isDisabled = true;
    if (organisation === "pratham_demo_1") {
      isDisabled = !(
        name !== "" &&
        classNum !== "" &&
        gender !== "" &&
        age !== "" &&
        instructorName !== "" &&
        phoneNumber !== ""
      );
    } else if (
      organisation === "demo" ||
      organisation === "generic_demo" ||
      organisation === "bihar_pilot_1" ||
      organisation === "bihar_demo_1"
    ) {
      isDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        profession !== ""
      );
    } else {
      isDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        profession !== ""
      );
    }
    setIsDisabled(isDisabled);
  }, [
    organisation,
    name,
    classNum,
    schoolUdise,
    gender,
    age,
    instructorName,
    phoneNumber,
    readingLevel,
    profession,
  ]);

  return (
    <div
      style={{
        textAlign: "center",
        margin: "20% 0%",
      }}
    >
      <div className="demo-input-field-heading-wrapper">
        <div className="vopa-input-field-screen-heading-text">
          {t("new_student")}
        </div>
        <div className="vopa-input-field-screen-subheading-text">
          {t("let_us_get_to_know_you_better_before_your_ORF_assessment")}
        </div>
      </div>
      <div className="demo-input-fields-container">
        {fieldsToShow.showInstructorName && (
          <div>
            <div className="input-label">
              Instructor Name
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={instructorName}
              setInputValue={setInstructorName}
              label={t("Instructor name")}
              autoComplete="on"
            />
          </div>
        )}
        {fieldsToShow.showPhoneNumber && (
          <div>
            <div className="input-label">
              Instructor Phone number
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={phoneNumber}
              setInputValue={setPhoneNumber}
              label={t("Phone number")}
              type="number"
            />
          </div>
        )}
        {fieldsToShow.showChildInfo && (
          <div>
            <div className="input-label">
              {getNameLabel(organisation)}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={name}
              setInputValue={setName}
              label={t("name")}
              autoComplete="on"
            />
          </div>
        )}
        {fieldsToShow.showGrade && (
          <div>
            <div className="input-label">
              {getGradeLabel(organisation)}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={classNum}
              setSelectedValue={setClassNum}
              options={getClassNumOptions(organisation)}
            />
          </div>
        )}
        {fieldsToShow.showProfession && (
          <div>
            <div className="input-label">
              {t("i_am_a")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={profession}
              setSelectedValue={setProfession}
              options={[
                {
                  value: "student",
                  label: "Student",
                },
                {
                  value: "teacher",
                  label: "Teacher",
                },
                {
                  value: "parent",
                  label: "Parent/Guardian",
                },
              ]}
            />
          </div>
        )}
        {fieldsToShow.showSchoolId && (
          <div>
            <div className="input-label">
              {getSchoolIdLabel(organisation)}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={schoolUdise}
              setInputValue={setSchoolUdise}
              label={getSchoolIdLabel(organisation)}
              type={getTextFieldType(organisation)}
            />
          </div>
        )}
        {fieldsToShow.showGender && (
          <div>
            <div className="input-label">
              Gender
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={gender}
              setSelectedValue={setGender}
              options={[
                {
                  value: "male",
                  label: "Male",
                },
                {
                  value: "female",
                  label: "Female",
                },
                {
                  value: "other",
                  label: "Other",
                },
              ]}
            />
          </div>
        )}
        {fieldsToShow.showAge && (
          <div>
            <div className="input-label">
              Age
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={age}
              setInputValue={setAge}
              label={t("age")}
              type="number"
            />
          </div>
        )}

        {fieldsToShow.showReadingLevel && (
          <div>
            <div className="input-label">
              Reading level as per oral one-on-one assessment
              {/* <span className="asterisk">*</span> */}
            </div>
            <CustomTextField
              inputValue={readingLevel}
              setInputValue={setReadingLevel}
              label={t("Reading level")}
              autoComplete="on"
            />
          </div>
        )}
      </div>
      <div
        style={{
          textAlign: "center",
        }}
        className="input-fields-screen-button"
      >
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          style={{
            height: "46px",
            width: "70%",
            textTransform: "none",
            borderRadius: "12px",
            color: "#fff",
            fontWeight: "400",
            fontSize: "18px",
            background: isDisabled ? "" : "#EB770C",
          }}
          disableElevation
          disabled={isDisabled}
          onClick={onClickNext}
        >
          {t("next")}
        </Button>
      </div>
    </div>
  );
};

export default VopaInputFieldsScreen;
