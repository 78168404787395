import React, { useState, useEffect } from "react";
import CustomErrorDialogBox from "./Components/CustomErrorDialogBox/CustomErrorDialogBox";
import { useTranslationSetup } from "./utils/useTranslationSetup.js";
import Loading from "./assets/LottieFiles/resultLoading.json";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Lottie from "react-lottie-player";
import "./ResultLoadingScreen.css";

const ResultLoadingScreen = ({
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showHomeScreen,
  setShowHomeScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  hideSlider,
  setHideSlider,
  orfResultStatus,
  setOrfResultStatus,
  saveClicked,
  setSaveClicked,
  isAudioUploading,
  setAudioUploading,
  uploadAudioStatus,
  setUploadAudioStatus,
  showOrfTestScreen,
  setShowOrfTestScreen,
  setName,
  setClassNum,
  setProfession,
  setSchoolUdise,
}) => {
  const { t } = useTranslationSetup();
  const [openDialogForError, setOpenDialogForError] = useState(false);

  useEffect(() => {
    if (
      orfResultStatus === "UNDETERMINED" ||
      orfResultStatus === "FAILED" ||
      orfResultStatus === "ERROR" ||
      orfResultStatus === "ERR_INTERNET_DISCONNECTED"
    ) {
      setOpenDialogForError(true);
    }
  }, [orfResultStatus]);

  const onClickPrimaryButton = () => {
    setOpenDialogForError(false);
    setShowResultLoadingScreen(false);
    setShowOrfTestScreen(true);
    setAudioBlobForAudioPlayer(null);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };
  const onClickSecondaryButton = () => {
    setOpenDialogForError(false);
    setAudioBlobForAudioPlayer(null);
    setShowResultLoadingScreen(false);
    setShowHomeScreen(true);
    setName("");
    setClassNum("");
    setProfession("");
    setSchoolUdise("");
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };

  return (
    <>
      {orfResultStatus === "UNDETERMINED" && (
        <CustomErrorDialogBox
          header={t("taking_longer_than_usual")}
          subHeader={t("assessment_taking_longer_than_usual")}
          icon={
            <RestoreOutlinedIcon
              style={{
                color: "rgba(228, 119, 20, 1)",
                borderRadius: "10px",
                marginTop: "16px",
                width: "70px",
                height: "70px",
              }}
            />
          }
          primaryButtonText={t("retake")}
          secondaryButtonText={t("new_student")}
          isDialogOpen={openDialogForError}
          showPrimaryButton={true}
          showSecondaryButton={true}
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
        />
      )}
      {(orfResultStatus === "FAILED" ||
        orfResultStatus === "ERROR" ||
        orfResultStatus === "ERR_INTERNET_DISCONNECTED") && (
        <CustomErrorDialogBox
          header={t("something_went_wrong")}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: "#D95959",
                borderRadius: "10px",
                marginTop: "16px",
                width: "80px",
                height: "80px",
              }}
            />
          }
          primaryButtonText={t("retake")}
          secondaryButtonText={t("new_student")}
          isDialogOpen={openDialogForError}
          showPrimaryButton={true}
          showSecondaryButton={true}
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
        />
      )}
      {/* <div>
        <Header
          heading={t("orf_assessment")}
          background="#1E5CD9"
          hideArrow={true}
        />
        {wordsTypeList.map((wordType) => (
          <>
            <div
              style={{
                marginTop: "40px",
              }}
            >
              <CustomLoadingCard text={wordType} />
            </div>
          </>
        ))}
      </div> */}
      <div className="loader-container">
        <div className="loader-content">
          <Lottie loop animationData={Loading} play />
          <h1 className="loader-heading">{t("processing_result")}</h1>
          <p className="loader-text">{t("your_audio_saved_please_wait")}</p>
        </div>
        <div className="loader-bottom-text-wrapper">
          <div className="loader-bottom-text">
            {t("thank_you_for_patience_fine_tuning")}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultLoadingScreen;
