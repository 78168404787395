import { useEffect, useState } from "react";

const createAssessmentApi = (
  url,
  studentName,
  selectedLaguage,
  requestBody
) => {
  const options = {
    method: "POST",
    body: JSON.stringify(
      // {
      //   student_id: `STV${new Date().getTime()}`,
      //   teacher_id: "teacher_test_1",
      //   lang: "en",
      //   grade: 3,
      //   organisation_id: "test_org",
      // }
      requestBody
    ),
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3NDkxODQ2MDV9.xiK7sEEaT8vDBSzNaoTnb9kzFlO8gtMIwsGa4FL18WE",
    },
  };

  return fetch(url, options).then((res) => {
    if (!res.ok) {
      return {
        status: "ERROR",
        data: "Error occurred...",
      };
    }
    return res.json();
  });
};

export default createAssessmentApi;
