import React from "react";
import "./NeedAssessComponent.css";

const NeedAssessComponent = ({ icon, heading, content }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "12px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#E47714",
            // margin: "2px 3% 0 0",
            borderRadius: "100%",
            padding: "8px",
            background: "#FBE7DB",
            height: "fit-content",
          }}
        >
          {icon}
        </div>
        <div>
          <div className="need-assess-heading">{heading}</div>
          <div className="need-assess-content">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default NeedAssessComponent;
