const fetchStatus = (url) => {
  return new Promise((resolve, reject) => {
    (async function requestStatus(startTime = Date.now()) {
      if (Date.now() - startTime > 60000) {
        resolve({
          status: "UNDETERMINED",
          data: "Unusual long run",
        });
        return;
      }

      try {
        const response = await fetch(url, {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3NDkxODQ2MDV9.xiK7sEEaT8vDBSzNaoTnb9kzFlO8gtMIwsGa4FL18WE",
          },
        });
        if (response.ok) {
          const data = await response.json();
          switch (data.status) {
            case "COMPLETED":
              resolve({
                status: "COMPLETED",
                data: data,
              });
              break;
            case "FAILED":
              resolve({
                status: "FAILED",
                data: data,
              });
              break;
            default:
              setTimeout(() => {
                requestStatus(startTime);
              }, 1000);
          }
        } else {
          resolve({
            status: "ERROR",
            data: "Error occurred while processing data..",
          });
          return;
        }
      } catch (error) {
        reject({
          status: "ERR_INTERNET_DISCONNECTED",
          data: "Network Error occurred...",
        });
      }
    })();
  });
};

export default fetchStatus;
