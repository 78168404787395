import React, { useState } from "react";
import CustomCard from "../CustomCard/CustomCard";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import IconButton from "@mui/material/IconButton";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import "./CustomExpandableCard.css";

const CustomExpandableCard = ({
  header,
  wordCount,
  wordsList
}) => {
  const [isExpand, setExpand] = useState(false);

  return (
    <div
      style={{
        paddingTop: "10px",
        fontFamily: "Open Sans"
      }}
    >
      <CustomCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "12px",
            paddingLeft: "19px",
            paddingBottom: "12px",
            paddingRight: "21px",
          }}
          onClick={() => setExpand(!isExpand)}
        >
          <div
            style={{
              paddingTop: "2px",
            }}
          >
            {header} - {wordCount}
          </div>
          <div className="expand-up-down">
            <IconButton
              style={{
                padding: "0px",
              }}
              // onClick={() => setExpand(!isExpand)}
            >
              {isExpand ? (
                <ArrowDropUpOutlinedIcon />
              ) : (
                <ArrowDropDownOutlinedIcon />
              )}
            </IconButton>
          </div>
        </div>
        {isExpand && (
          <div
            style={{
              marginLeft: "19px",
              marginBottom: (wordsList.length > 0) && "8px",
            }}
          >
            <div
              style={{
                marginRight: "10px",
              }}
            >
              {wordsList.map((word) => (
                <div
                  style={{
                    display: "inline-block",
                    padding: "2px 6px",
                    background: "#D9D9D9",
                    borderRadius: "2px",
                    lineHeight: "30px",
                    margin: "4px 4px 3px 0px",
                  }}
                >
                  {word}
                </div>
              ))}
            </div>
          </div>
        )}
      </CustomCard>
    </div>
  );
};

export default CustomExpandableCard;
