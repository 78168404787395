export const QueryParams = Object.freeze({
  ORGNISATION: "organisation",
  APP_LANGUAGE: "app_language"
});

export const UrlConstants = Object.freeze({
  BASE_URL:
    // process.env.NODE_ENV === "production"
    //   ?
    "https://orf-orchestrator.wadhwaniai.org/",
    // :
    // "https://orf-orchestrator-staging.wadhwaniai.org/",
    // "https://vachan-samiksha.wadhwaniai.org/",
  BASE_PATH_URL: "api/v1/orf/",
  ASSESSMENT: "assessment",
});
