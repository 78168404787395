import DemoApp from "./apps/DemoApp";
// import GshalaApp from "./apps/GshalaApp";
// import VopaApp from "./apps/VopaApp";
import useParamValues from "./utils/useParamValues";

function App() {
  const { organisation } = useParamValues();

  switch (organisation) {
    case "demo":
      return <DemoApp organisation={organisation} />;
    case "bihar_demo_1":
      return <DemoApp organisation={organisation} />;
    // case "vopa":
    //   return <VopaApp />;
    // case "gshala":
    //   return <GshalaApp />;
    default:
      return <DemoApp organisation={organisation} />;
  }
}

export default App;
