import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./CustomErrorDialogBox.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";

const CustomErrorDialogBox = ({
  header,
  subHeader,
  icon,
  isDialogOpen,
  primaryButtonText,
  secondaryButtonText,
  showPrimaryButton,
  showSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
}) => {
  return (
    <Dialog
      className="error-dialog"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
        }}
      >
        {icon}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#000000",
              fontWeight: "500",
              letterSpacing: "-1.9%",
              fontFamily: "Open Sans",
            }}
          >
            {header}
          </Typography>
          {subHeader && (
            <Typography
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#000000",
                fontWeight: "400",
                letterSpacing: "-1.9%",
                fontFamily: "Open Sans",
                padding: "4px 0 0",
              }}
            >
              {subHeader}
            </Typography>
          )}
          {showPrimaryButton && (
            <div
              style={{
                display: "flex",
                marginTop: "22px",
              }}
            >
              <Button
                variant="contained"
                startIcon={<LoopOutlinedIcon className="retake-icon" />}
                style={{
                  height: "48px",
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "12px",
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "16px",
                  background: "rgba(228, 119, 20, 1)",
                  fontFamily: "Open Sans",
                }}
                disableElevation
                onClick={onClickPrimaryButton}
              >
                {primaryButtonText}
              </Button>
            </div>
          )}
          {showSecondaryButton && (
            <div
              style={{
                display: "flex",
                marginTop: "16px",
              }}
            >
              <Button
                variant={showPrimaryButton ? "text" : "contained"}
                startIcon={
                  <KeyboardReturnIcon
                    sx={{
                      paddingRight: "2px",
                    }}
                  />
                }
                style={{
                  height: "48px",
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "12px",
                  color: showPrimaryButton ? "#E47714" : "#FFF",
                  background: !showPrimaryButton ? "#E47714" : "#FFF",
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  border: showPrimaryButton && "1px solid #E47714",
                  fontFamily: "Open Sans",
                }}
                onClick={onClickSecondaryButton}
                disableElevation
              >
                {secondaryButtonText}
              </Button>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CustomErrorDialogBox;
