import React from "react";
import "./TimerGif.css";
import Lottie from "react-lottie-player";
import timerJson from "../../../assets/LottieFiles/time.json";

const TimerGif = () => {
  return (
    <div>
      <Lottie
        loop
        animationData={timerJson}
        play
        style={{
          width: "100%",
          height: "132px",
          margin: "43px 0 43px",
          filter:
            "drop-shadow(0px 6px 37px rgba(0, 0, 0, 0.13)) drop-shadow(0px 11px 89px rgba(237, 116, 46, 0.18))",
        }}
      />
    </div>
  );
};

export default TimerGif;
