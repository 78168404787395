import { TextField } from "@mui/material";
import React from "react";
import "./CustomTextField.css";

const CustomTextField = ({ inputValue, setInputValue, label, type, autoComplete }) => {
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      style={{
        width: "100%",
        color: "#BB248A",
      }}
      className="outlined-textfield"
      // label={label}
      placeholder={label}
      variant="outlined"
      value={inputValue}
      onChange={handleInputChange}
      autoComplete={autoComplete}
      type={type}
    />
  );
};

export default CustomTextField;
