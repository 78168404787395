import { Dialog, DialogTitle } from "@mui/material";
import React from "react";
import "./GrantPermissionsStepsDialog.css";
import audioLocationPermissionsImg from "../../assets/audio_location_permissions_img.svg";
import { useTranslationSetup } from "../../utils/useTranslationSetup.js";
import LockIcon from '@mui/icons-material/Lock';
import TuneIcon from "@mui/icons-material/Tune";
import MicNoneIcon from '@mui/icons-material/MicNone';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import page_info from '../../assets/page_info.png'; 

const GrantPermissionsStepsDialog = ({ isDialogOpen, setIsDialogOpen }) => {
  const { t } = useTranslationSetup();
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  return (
    <Dialog
      className="grant-permissions-steps-dialog"
      open={isDialogOpen}
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
        }}
      >
        <div className="grant-permissions-steps-dialog-heading">
          {t("you_havent_allowed_access_to_location_mic")}
        </div>
        <div>
          <img
            src={audioLocationPermissionsImg}
            alt="audio-location-permissions-img"
          />
        </div>
        <div className="grant-permissions-steps-dialog-content">
          {t("reload_the_screen_after_following_steps")}
        </div>
        <div className="grant-permissions-steps">
          {t("click_on")}
          <LockIcon
            sx={{
              background: "#EFEFEF",
              borderRadius: "2px",
              fontSize: "14px",
              margin: "0 4px -5px 4px",
              padding: "2px",
              color: "#636363",
            }}
          />{"/"}
           <img
            src={page_info}
            alt="page_info"
            style={{
              width: "14px", 
              height: "14px",
              background: "#EFEFEF",
              borderRadius: "2px",
              fontSize: "14px",
              margin: "2px 4px -5px 4px",
              padding: "2px",
              color: "#636363",
            }}
          />
          {t("at_the_top_left_corner")} &gt; {t("click_on")} {t("permissions")}
          <TuneIcon
            sx={{
              background: "#EFEFEF",
              borderRadius: "2px",
              fontSize: "14px",
              margin: "2px 4px -6px 4px",
              padding: "2px",
              color: "#636363",
            }}
          />
          &gt; {t("click_on_button")} 
          {/* &gt; {t("microphone")} */}
          <ToggleOffOutlinedIcon
            sx={{
              background: "#EFEFEF",
              borderRadius: "2px",
              fontSize: "14px",
              margin: "2px 4px -5px 4px",
              padding: "2px",
              color: "#636363",
            }}
          />
           {t("against Microphone")}
          <MicNoneIcon
            sx={{
              background: "#EFEFEF",
              borderRadius: "2px",
              fontSize: "14px",
              margin: "2px 4px -5px 4px",
              padding: "2px",
              color: "#636363",
            }}
          />
          / {t("location")} 
          <LocationOnOutlinedIcon
            sx={{
              background: "#EFEFEF",
              borderRadius: "2px",
              fontSize: "14px",
              margin: "2px 4px -5px 4px",
              padding: "2px",
              color: "#636363",
            }}
            />
          &gt; {t("click_outside_pop-up")} &gt; {t("reload")}
          
        </div>
      </DialogTitle>
    </Dialog>
  );
};

export default GrantPermissionsStepsDialog;
