import React, { useState, useEffect } from "react";
import Header from "./Header";
import { CircularProgress } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import createAssessmentApi from "./FetchApi/createAssessmentApi";
import CustomErrorDialogBox from "./Components/CustomErrorDialogBox/CustomErrorDialogBox";
import { useTranslationSetup } from "./utils/useTranslationSetup.js";
import { UrlConstants } from "./utils/CommonUtils/constants.js";
import girlHoldingPhoneHi from "./assets/girl_holding_phone_hi_letter.svg";
import girlHoldingPhoneEn from "./assets/girl_holding_phone_en_letter.svg";
import sendFeedback from "./FetchApi/sendFeedback.js";

const EnterOrfApplicationLoadingScreen = ({
  enrollmentNumber,
  setParaData,
  studentName,
  setStudentName,
  showVopaInputFieldsScreen,
  setShowVopaInputFieldsScreen,
  showSplashLoadingScreen,
  setShowSplashLoadingScreen,
  selectedLaguage,
  setSelectedLaguage,
  organisation,
  requestBody,
  setFeedbackResponse,
  feedbackData,
  assessmentLang,
  processedData,
}) => {
  const [assessmentCreatingStatus, setAssessmentCreatingStatus] =
    useState(null);
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const { t } = useTranslationSetup();

  // useEffect(() => {
  //   setTimeout(() => {
  //     createAssessmentApi(
  //       UrlConstants.BASE_URL +
  //         UrlConstants.BASE_PATH_URL +
  //         UrlConstants.ASSESSMENT,
  //       studentName,
  //       selectedLaguage,
  //       requestBody
  //     )
  //       .then((response) => {
  //         if (response.status === "ERROR") {
  //           setOpenDialogForError(true);
  //           setAssessmentCreatingStatus(response.status);
  //         } else {
  //           setParaData(response);
  //           const feedbackUrl = `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}/${response.id}/feedback`;
  //           sendFeedback(feedbackData, feedbackUrl)
  //             .then((response) => {
  //               if (response.status === "ERROR") {
  //                 setOpenDialogForError(true);
  //                 setAssessmentCreatingStatus("ERROR");
  //               }
  //               else{
  //                 setOpenDialogForError(false);
  //               }
  //             })
  //             .catch((error) => {
  //               setOpenDialogForError(true);
  //               setAssessmentCreatingStatus("ERR_INTERNET_DISCONNECTED");
  //             });
  //         }
  //       })
  //       .catch((error) => {
  //         setOpenDialogForError(true);
  //         setAssessmentCreatingStatus("ERR_INTERNET_DISCONNECTED");
  //       });
  //   }, 3000);
  //   console.log(assessmentCreatingStatus);
  // }, []);
  useEffect(() => {
    const createAssessment = async () => {
      try {
        const response = await createAssessmentApi(
          `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}`,
          studentName,
          selectedLaguage,
          requestBody
        );

        if (response.status === "ERROR") {
          handleAssessmentError();
        } else {
          handleAssessmentSuccess(response);
        }
      } catch (error) {
        handleAssessmentError("ERR_INTERNET_DISCONNECTED");
      }
    };

    const handleAssessmentSuccess = (response) => {
      setParaData(response);
      if (organisation === "pratham_demo_1") {
        const feedbackUrl = `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}/${response.id}/feedback`;
        sendFeedbackData(feedbackUrl);
      }
    };

    const sendFeedbackData = async (feedbackUrl) => {
      try {
        const response = await sendFeedback(feedbackData, feedbackUrl);
        if (response.status === "ERROR") {
          handleAssessmentError(response.status);
        } else {
          setFeedbackResponse(response);
        }
      } catch (error) {
        handleAssessmentError("ERR_INTERNET_DISCONNECTED");
      }
    };

    const handleAssessmentError = (errorType = "ERROR") => {
      setOpenDialogForError(true);
      setAssessmentCreatingStatus(errorType);
    };

    const assessmentTimeout = setTimeout(createAssessment, 3000);

    return () => clearTimeout(assessmentTimeout);
  }, []);

  return (
    <>
      {(assessmentCreatingStatus === "ERROR" ||
        assessmentCreatingStatus === "ERR_INTERNET_DISCONNECTED") && (
        <CustomErrorDialogBox
          header={t("something_went_wrong")}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: "#D95959",
                borderRadius: "10px",
                marginTop: "16px",
                width: "80px",
                height: "80px",
              }}
            />
          }
          secondaryButtonText={t("try_again")}
          isDialogOpen={openDialogForError}
          showPrimaryButton={false}
          showSecondaryButton={true}
          onClickSecondaryButton={() => {
            setOpenDialogForError(false);
            setShowSplashLoadingScreen(false);
            setShowVopaInputFieldsScreen(true);
          }}
        />
      )}
      <div>
        <Header
          heading={t("orf_assess")}
          background="linear-gradient(134.56deg, #E47714 59.55%, rgba(228, 119, 20, 0.4) 152.08%)"
          hideArrow={true}
        />
        <div
          style={{
            fontFamily: "Open Sans",
            fontWeight: "400",
            marginTop: "8px",
            textAlign: "center",
            color: "#373737",
          }}
        >
          <div
            style={{
              fontSize: "20px",
            }}
          >
            {studentName}
          </div>
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
              letterSpacing: "-1.9%",
              lineHeight: "24px",
              padding: "4px 0 0",
            }}
          >
            {`${assessmentLang.label} ${t("assessment")}`}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-65%, -50%)",
          }}
        >
          <picture>
            <img
              style={{
                height: "190px",
              }}
              src={
                assessmentLang.value === "hi"
                  ? girlHoldingPhoneHi
                  : girlHoldingPhoneEn
              }
              alt="welcome-logo"
            />
          </picture>
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "8%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              fontFamily: "Open Sans",
              fontWeight: "400",
              color: "#000000",
            }}
          >
            {t("loading")}
            <div>
              <CircularProgress size="12px" background="black" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOrfApplicationLoadingScreen;
