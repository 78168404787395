import React, { useEffect, useState } from 'react';
import "./SplashScreen.css";
import graphic from "../../assets/graphicEq.svg";

const SplashScreen = ({setSplash = () => {}}) => {
    const alphabetArray = ["A", "क", "B", "ख", "C", "ग"];
    const [currentIndex, setCurrentIndex] = useState(0);

    const getStarted = () => {
      setSplash(false);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % alphabetArray.length);
        }, 800);

        return () => clearInterval(intervalId);
      }, []); // Run the effect once when the component mounts
  return (
    <div className='splashScreenDiv'>
        <div className='headingText'>Oral Reading Fluency</div>
        <div className='container'>
            <div className="ellipse" id="ellipse1"></div>
            <div className="ellipse" id="ellipse2"></div>
            <div className="ellipse" id="ellipse3">
                <div className="letter">{alphabetArray[currentIndex]}</div>
            </div>
        </div>
        <div className='graphicDiv'>
            <img src={graphic} alt='Listening'/>
        </div>
        <div className='getStartedButton' onClick={getStarted}>Get Started</div>
    </div>
  )
}

export default SplashScreen