const sendFeedback = (feedbackData, feedbackUrl) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3NDkxODQ2MDV9.xiK7sEEaT8vDBSzNaoTnb9kzFlO8gtMIwsGa4FL18WE",
    },
    body: JSON.stringify(feedbackData),
  };

  return fetch(feedbackUrl, options).then((res) => {
    if (!res.ok) {
      return {
        status: "ERROR",
        data: "Error occurred...",
      };
    } else {
      return {
        status: "Ok",
        data: res.json(),
      };
    }
  });
};

export default sendFeedback;
