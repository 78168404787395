import React, { useState } from "react";
import "./DemoResultsScreen.css";
import resultImage from "../../assets/resultImageClouds.svg";
import InfoCards from "../../Components/InfoCards/InfoCards";
import countIcn from "../../assets/countIcon.svg";
import wordsIcn from "../../assets/wordsIcon.svg";
import quickSightIcn from "../../assets/quickSightIcon.svg";
import ResultCard from "../../Components/ResultCard/ResultCard";
import correct from "../../assets/correct.svg";
import missed from "../../assets/missed.svg";
import extra from "../../assets/extra.svg";
import incorrect from "../../assets/incorrect.svg";
import enSpreadedLetters from "../../assets/en_spreaded_letters.svg";
import hiSpreadedLetters from "../../assets/hi_spreaded_letters.svg";
import AudioPlayer from "../../AudioPlayer";
import DetailedResultCard from "../../Components/DetailedResultCard/DetailedResultCard";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {
  Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // TextareaAutosize,
  // Typography,
} from "@mui/material";
// import girlImage from "../../assets/girlImage.svg";
import { useTranslationSetup } from "../../utils/useTranslationSetup";

const DemoResultsScreen = ({
  name,
  classNum,
  processedData,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer = () => {},
  setSplash,
  setShowOrfResultScreen,
  setShowAssessmentLangScreen,
  setShowVopaInputFieldsScreen,
  setHideSlider,
  setOrfResultStatus,
  setUploadAudioStatus,
  setAudioUploading,
  setSaveClicked,
  setName,
  setClassNum,
  setProfession,
  setSchoolUdise,
  setGender,
  setAge,
  setInstructorName,
  setPhoneNumber,
  setReadingLevel,
}) => {
  const [expandText, setExpandText] = useState(false);
  // const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  // const [feedback, setFeedback] = useState("");
  // const [rating, setRating] = useState(0);
  const { t } = useTranslationSetup();

  // const StarRating = ({ value, onChange }) => {
  //   const stars = [1, 2, 3, 4, 5];

  //   const handleStarClick = (starValue) => {
  //     onChange(starValue);
  //   };

  //   const handleFeedbackSubmit = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://orf-orchestrator-staging.wadhwaniai.org/api/v1/orf/assessment/${processedData.id}/feedback`,
  //         {
  //           method: "PUT",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization:
  //               "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3MTc2MDU4MDB9.m4J7eVK0R3jNGv37nE3OKl2Tt6tO7qbZ1eoHU81AzVs",
  //           },
  //           body: JSON.stringify({
  //             feedback: {
  //               user_feedback: feedback,
  //               score: rating,
  //             },
  //           }),
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to submit feedback");
  //       }

  //       console.log("Feedback submitted successfully");
  //       setIsFeedbackModalOpen(false);
  //       setSplash(true);
  //     } catch (error) {
  //       console.error("Error submitting feedback:", error.message);
  //     }
  //   };
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         marginBottom: "10px",
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "inline-block",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           marginBottom: "10px",
  //         }}
  //       >
  //         {stars.map((star) => (
  //           <svg
  //             key={star}
  //             onClick={() => handleStarClick(star)}
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="44"
  //             height="43"
  //             viewBox="0 0 44 43"
  //             fill={star <= value ? "#8645A4" : "none"}
  //             stroke={star <= value ? "" : "#000000"}
  //             style={{ marginTop: "10px", alignItems: "center" }}
  //           >
  //             <path
  //               d="M9.82614 8.07917L14.3949 1.94271C14.6577 1.58823 14.9697 1.32792 15.331 1.16177C15.6924 0.995595 16.082 0.912506 16.5001 0.912506C16.9182 0.912506 17.3078 0.995595 17.6692 1.16177C18.0305 1.32792 18.3425 1.58823 18.6053 1.94271L23.1741 8.07917L30.2511 10.4979C30.8484 10.707 31.3037 11.0453 31.6173 11.5129C31.9308 11.9806 32.0876 12.4971 32.0876 13.0626C32.0876 13.3237 32.0493 13.5821 31.9726 13.8381C31.896 14.0941 31.7701 14.3393 31.5949 14.574L26.9366 20.8L27.1157 27.5188C27.1456 28.2995 26.8866 28.9575 26.3387 29.4928C25.7908 30.0282 25.154 30.2958 24.4282 30.2958C24.4581 30.2958 24.2043 30.266 23.6668 30.2063L16.5001 28.0563L9.33343 30.2063C9.18413 30.266 9.04624 30.2958 8.91978 30.2958H8.57197C7.82544 30.2958 7.18343 30.0282 6.64593 29.4928C6.10843 28.9575 5.85461 28.2995 5.88447 27.5188L6.06364 20.7552L1.40531 14.574C1.23011 14.3364 1.1042 14.0879 1.02758 13.8287C0.950924 13.5695 0.912598 13.3103 0.912598 13.051C0.912598 12.4887 1.07792 11.9658 1.40858 11.4822C1.7392 10.9986 2.20096 10.6556 2.79385 10.4531L9.82614 8.07917Z"
  //               fillOpacity="0.5"
  //             />
  //           </svg>
  //         ))}
  //       </div>
  //       {value !== 0 && (
  //         <Button
  //           onClick={() => handleFeedbackSubmit()}
  //           variant="contained"
  //           sx={{
  //             display: "inline-block",
  //             padding: "11px 86px",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             gap: "10px",
  //             borderRadius: "7px",
  //             background: "#E47714",
  //             marginLeft: "6px",
  //             marginBottom: "10px",
  //           }}
  //         >
  //           <Typography
  //             variant="body4"
  //             style={{
  //               color: "#FFF",
  //               fontFamily: "Poppins",
  //               fontSize: "16px",
  //               fontStyle: "normal",
  //               fontWeight: 400,
  //               lineHeight: "24px",
  //               textAlign: "center",
  //             }}
  //           >
  //             Submit
  //           </Typography>
  //         </Button>
  //       )}
  //     </div>
  //   );
  // };
  // const handleLoginClick = () => {
  //   setIsFeedbackModalOpen(true);
  // };

  const handleNavigateToSelectAssessmentLangScreen = () => {
    setShowOrfResultScreen(false);
    setShowAssessmentLangScreen(true);
    setAudioBlobForAudioPlayer(null);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };

  const handleNavigateToInputFieldsScreen = () => {
    setShowOrfResultScreen(false);
    setShowVopaInputFieldsScreen(true);
    setName("");
    setClassNum("");
    setProfession("");
    setSchoolUdise("");
    setGender("");
    setAge("");
    setInstructorName("");
    setPhoneNumber("");
    setReadingLevel("");
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };

  const detailedResult = [
    {
      heading: t("correct_words"),
      subHeading: t("correct_words_detail"),
      bgColor: "rgba(126, 182, 107, 0.17)",
      innerBorderColor: "#7EB66B",
      icon: correct,
      words: processedData.result["correct_words"],
    },
    {
      heading: t("missed_words"),
      subHeading: t("missed_words_detail"),
      bgColor: "rgba(154, 23, 201, 0.07)",
      innerBorderColor: "#9A17C9",
      icon: missed,
      words: processedData.result["missed_words"],
    },
    {
      heading: t("extra_words"),
      subHeading: t("extra_words_detail"),
      bgColor: "rgba(95, 188, 240, 0.13)",
      innerBorderColor: "#5FBCF0",
      icon: extra,
      words: processedData.result["extra_words"],
    },
    {
      heading: t("incorrect_words"),
      subHeading: t("incorrect_words_detail"),
      bgColor: "rgba(212, 43, 83, 0.13)",
      innerBorderColor: "#D42B53",
      icon: incorrect,
      words: processedData.result["incorrect_words"],
    },
  ];
  return (
    <div className="resultPage">
      <div className="header">
        <div className="headerText">{name}</div>
        <div className="headerText">
          {t("grade")} {classNum}
        </div>
      </div>
      <hr className="divider" />
      <div className="imageContainer">
        <img src={resultImage} alt="Result" />
      </div>
      <div className="infoCardsContainer">
        <InfoCards
          icon={countIcn}
          info={t("cwpm")}
          value={`${processedData.result.cwpm}`}
        />
        <div className="cwpm-info">{t("cwpm_correct_words_per_minute")}</div>
        <InfoCards
          icon={wordsIcn}
          info={t("words_read_aloud")}
          value={`${processedData.result.total_words_read}/${processedData.result.total_words}`}
        />
      </div>
      <div className="quicksight-card">
        <div className="quicksight-card-header">
          {t("quick_sight")}
          <img src={quickSightIcn} alt="Quick Sight" />
        </div>
        <div className="quicksight-grid">
          <ResultCard
            heading={t("correct_words")}
            headingColor={"#7EB66B"}
            bgColor={"rgba(126, 182, 107, 0.12)"}
            value={processedData.result["correct_words"].length}
            image={correct}
          />
          <ResultCard
            heading={t("missed_words")}
            headingColor={"#9A17C8"}
            bgColor={"rgba(154, 23, 200, 0.12)"}
            value={processedData.result["missed_words"].length}
            image={missed}
          />
          <ResultCard
            heading={t("extra_words")}
            headingColor={"#5FBCF0"}
            bgColor={"rgba(95, 188, 240, 0.12)"}
            value={processedData.result["extra_words"].length}
            image={extra}
          />
          <ResultCard
            heading={t("incorrect_words")}
            headingColor={"#E70000"}
            bgColor={"rgba(212, 43, 83, 0.12)"}
            value={processedData.result["incorrect_words"].length}
            image={incorrect}
          />
        </div>
      </div>
      <div className="paragraph-wrapper-card">
        <div className="paragraph-card">
          <div className="para-heading">{t("paragraph")}</div>
          {audioBlobForAudioPlayer && (
            <div className="audio-player-wrapper">
              <div className="audio-player">
                <AudioPlayer
                  audioBlobForAudioPlayer={audioBlobForAudioPlayer}
                  setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
                  testedAudioElementRef={false}
                />
              </div>
            </div>
          )}
          <div
            className="paragraph-para"
            style={{ WebkitLineClamp: !expandText ? 3 : "" }}
            onClick={() => setExpandText(!expandText)}
          >
            {processedData["paragraph"]}
          </div>
        </div>
      </div>
      <div className="detailed-result-card">
        <div className="detailed-result-card-heading">
          {t("detailed_result")}
        </div>
        <div className="detailed-result-grid">
          {detailedResult.map((res, idx) => {
            return (
              <DetailedResultCard
                key={idx}
                heading={res.heading}
                subHeading={res.subHeading}
                bgColor={res.bgColor}
                icon={res.icon}
                innerBorderColor={res.innerBorderColor}
                words={res.words}
              />
            );
          })}
        </div>
      </div>
      <div className="resultPage-btn-wrapper">
        {/* <Dialog
          open={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
          }}
          className="feedback-dialog"
        >
          <DialogTitle>
            <img
              src={girlImage}
              alt="Girl"
              style={{ width: "267px", height: "120.53px" }}
            />
            <Typography
              variant="h6"
              style={{
                color: "#373737",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "30px",
                textAlign: "center",
              }}
            >
              Thank You
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#797B7C",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "21px",
                textAlign: "center",
              }}
            >
              Thank you for using ORF.
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#797B7C",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "21px",
                textAlign: "center",
              }}
            >
              Your submission has been recorded.
            </Typography>
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText>
              <Typography
                variant="body1"
                style={{
                  color: "#373737",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "21px",
                }}
              >
                City{" "}
                <Typography
                  variant="body2"
                  component="span"
                  style={{
                    color: "#373737",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "18px",
                  }}
                >
                  (optional)
                </Typography>
              </Typography>

              <TextareaAutosize
                rows={1}
                minRows={1}
                maxRows={1}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                style={{
                  width: "271px",
                  height: "48px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                  background: "rgba(55, 55, 55, 0.03)",
                  resize: "none",
                  padding: "5px",
                  fontFamily: "Poppins",
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: "#373737",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "150%",
                textAlign: "center",
              }}
            >
              Give us your feedback
            </Typography>
            <StarRating value={rating} onChange={setRating} />
          </DialogActions>
        </Dialog> */}

        <Button
          endIcon={<ArrowForwardOutlinedIcon />}
          style={{
            width: "100%",
            height: "50px",
            textTransform: "none",
            borderRadius: "12px",
            color: "#fff",
            fontWeight: "400",
            fontSize: "16px",
            fontFamily: "Poppins",
            margin: "2vh 0 0",
            background:
              "linear-gradient(90deg, rgba(45, 167, 255, 0.98) 0%, #FF5EA1 100%)",
            border: "1px solid rgba(64, 64, 64, 0.24)",
          }}
          onClick={handleNavigateToSelectAssessmentLangScreen}
        >
          <img
            src={enSpreadedLetters}
            alt="en-spreaded-letters"
            className="en-spreaded-letters"
          />
          {t("try_another_langauge")}
          <img
            src={hiSpreadedLetters}
            alt="hi-spreaded-letters"
            className="hi-spreaded-letters"
          />
        </Button>
        <Button
          style={{
            height: "49px",
            width: "100%",
            textTransform: "none",
            borderRadius: "12px",
            color: "#E47714",
            fontWeight: "400",
            fontSize: "16px",
            border: "1px solid rgba(228, 119, 20, 1)",
            fontFamily: "Poppins",
            margin: "2vh 0 0",
          }}
          onClick={handleNavigateToInputFieldsScreen}
          startIcon={<KeyboardReturnIcon />}
        >
          {t("new_assessment")}
        </Button>
      </div>
    </div>
  );
};

export default DemoResultsScreen;
