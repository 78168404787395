import React from "react";
import Header from "./Header";
import TextResizer from "./TextResizer";
import { useTranslationSetup } from "./utils/useTranslationSetup.js";

const OrfTestScreen = ({
  hideArrow,
  setHideArrow,
  hideSlider,
  setHideSlider,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  paragraph,
  showOrfResultScreen,
  setShowOrfResultScreen,
  processedData,
  setProcessedData,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  studentName,
  setStudentName,
  orfResultStatus,
  setOrfResultStatus,
  selectedLaguage,
  setSelectedLaguage,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  saveClicked,
  setSaveClicked,
  isAudioUploading,
  setAudioUploading,
  uploadAudioStatus,
  setUploadAudioStatus,
  setProfession,
}) => {
  const { t } = useTranslationSetup();

  return (
    <>
      <Header
        heading={t("student_orf_test", {
          firstName: studentName.split(" ")[0],
        })}
        color="#373737"
        hideArrow={true}
      />
      <TextResizer
        hideSlider={hideSlider}
        setHideSlider={setHideSlider}
        hideArrow={hideArrow}
        setHideArrow={setHideArrow}
        showResultLoadingScreen={showResultLoadingScreen}
        setShowResultLoadingScreen={setShowResultLoadingScreen}
        showOrfTestScreen={showOrfTestScreen}
        setShowOrfTestScreen={setShowOrfTestScreen}
        audioBlobForAudioPlayer={audioBlobForAudioPlayer}
        setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
        paragraph={paragraph}
        showOrfResultScreen={showOrfResultScreen}
        setShowOrfResultScreen={setShowOrfResultScreen}
        processedData={processedData}
        setProcessedData={setProcessedData}
        assessmentId={assessmentId}
        setAssessmentId={setAssessmentId}
        showHomeScreen={showHomeScreen}
        setShowHomeScreen={setShowHomeScreen}
        studentName={studentName}
        setStudentName={setStudentName}
        orfResultStatus={orfResultStatus}
        setOrfResultStatus={setOrfResultStatus}
        selectedLaguage={selectedLaguage}
        setSelectedLaguage={setSelectedLaguage}
        classNum={classNum}
        setClassNum={setClassNum}
        schoolUdise={schoolUdise}
        setSchoolUdise={setSchoolUdise}
        saveClicked={saveClicked}
        setSaveClicked={setSaveClicked}
        isAudioUploading={isAudioUploading}
        setAudioUploading={setAudioUploading}
        uploadAudioStatus={uploadAudioStatus}
        setUploadAudioStatus={setUploadAudioStatus}
        setProfession={setProfession}
      />
    </>
  );
};

export default OrfTestScreen;
