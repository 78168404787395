import { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./AdditionalFeedbackDialog.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import dislikeFeedbackImage from "../../assets/dislikeFeedbackImage.svg";
import submitButtonIcon from "../../assets/submitButtonIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const AdditionalFeedbackDialog = ({
  isDialogOpen,
  setDialogOpen,
  individualInputFeedback,
  setIndividualInputFeedback,
  individualCheckboxValues,
  setIndividualCheckboxValues,
}) => {
  const [inputFeedback, setInputFeedback] = useState("");
  const [checkboxValues, setCheckboxValues] = useState([]);
  const checkboxOptions = [
    { value: "Inaccurate information", label: "Inaccurate information" },
    { value: "False word count", label: "False word count" },
    { value: "Mismatch information", label: "Mismatch information" },
    { value: "Repeated information", label: "Repeated information" },
  ];

  const handleClose = () => {
    setDialogOpen(false);
    setInputFeedback("");
    setCheckboxValues([]);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (checkboxValues.includes(value)) {
      // Remove the value if it's already present
      setCheckboxValues(checkboxValues.filter((val) => val !== value));
    } else {
      // Add the value if it's not present
      setCheckboxValues([...checkboxValues, value]);
    }
  };

  return (
    <Dialog
      className="additionalFeedback"
      open={isDialogOpen}
      aria-labelledby="additional-feedback-title"
      aria-describedby="additional-feedback-dialog-description"
      style={{ borderRadius: "20px" }}
    >
      <div className="additional-feedback-dialog-title">
        <DialogTitle id="additional-feedback-dialog-title">
          <div
            style={{
              display: "flex",
            }}
          >
            <img
              src={dislikeFeedbackImage}
              alt="feedbackImage"
              style={{
                height: "22px",
                width: "22px",
              }}
            />
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#373737",
                marginLeft: "16px",
              }}
            >
              Additional Feedback
            </Typography>
            <div onClick={() => handleClose()}>
              <CloseOutlinedIcon
                sx={{
                  position: "absolute",
                  height: "19px",
                  width: "19px",
                  right: "0",
                  marginRight: "15px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              left: "0",
              right: "0",
              borderTop: "1px solid #D7D7D7",
              marginTop: "10px",
            }}
          ></div>
        </DialogTitle>
      </div>
      <DialogContent>
        <DialogContentText id="additional-feedback-dialog-description">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#373737",
            }}
          >
            Your feedback helps us improve. Please share the reason for your
            dissatisfaction.
          </Typography>
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              padding: "10px 14px 0px 14px",
              marginTop: "12px",
              borderRadius: "8px",
              backgroundColor: "#D7D7D7",
            }}
          >
            <textarea
              className="feedback-input-text-field"
              style={{
                background: "#D7D7D7",
              }}
              placeholder="Please type here..."
              value={inputFeedback}
              onInput={(event) => {
                event.target.style.height = "auto";
                event.target.style.height = `${event.target.scrollHeight}px`;
              }}
              onChange={(event) => {
                setInputFeedback(event.target.value);
              }}
            />
          </div>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            <FormGroup className="feedback-form">
              {checkboxOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={checkboxValues.includes(option.value)}
                      onChange={handleChange}
                      value={option.value}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "8px",
            }}
          >
            <Button
              style={{
                textTransform: "capitalize",
                borderRadius: "12px",
              }}
              variant="outlined"
              startIcon={<img src={submitButtonIcon} alt="submitButtonIcon" />}
              onClick={() => {
                setIndividualInputFeedback(inputFeedback);
                setIndividualCheckboxValues(checkboxValues);
                handleClose();
              }}
            >
              Submit
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalFeedbackDialog;
