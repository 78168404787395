import React from "react";

const uploadAudio = (url, audioBlobForAudioPlayer) => {
  const formData = new FormData();

  formData.append("file", audioBlobForAudioPlayer, "filename.mp3");

  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3NDkxODQ2MDV9.xiK7sEEaT8vDBSzNaoTnb9kzFlO8gtMIwsGa4FL18WE",
    },
  };

  return fetch(url, options).then((response) => {
    if (response.ok) {
      return {
        status: "COMPLETED",
      };
    } else {
      return {
        status: "ERROR",
      };
    }
  });
};

export default uploadAudio;
