import React from "react";
import "./CardsGroupComponent.css";

const CardsGroupComponent = ({ children }) => {
  return (
    <div className="cards">
      <div className="card-1">{children}</div>
      <div className="card-2"></div>
      <div className="card-3"></div>
      <div className="card-4"></div>
    </div>
  );
};

export default CardsGroupComponent;
