import VopaInputFieldsScreen from "../screens/VopaInputFieldsScreen/VopaInputFieldsScreen.js";
import VopaWelcomeScreen from "../screens/VopaWelcomeScreen/VopaWelcomeScreen";
import VopaInstructionScreen from "../screens/VopaInstructionScreen/VopaInstructionScreen";
import NeedPermissionsScreen from "../screens/NeedPermissionsScreen/NeedPermissionsScreen";
import ResultLoadingScreen from "../ResultLoadingScreen.js";
import { useState, useEffect } from "react";
import EnterOrfApplicationLoadingScreen from "../EnterOrfApplicationLoadingScreen";
import OrfResultScreen from "../OrfResultScreen";
import OrfTestScreen from "../OrfTestScreen";
import { useTranslation } from "react-i18next";
import { useTranslationSetup } from "../utils/useTranslationSetup.js";
import useParamValues from "../utils/useParamValues.js";
import DemoResultsScreen from "../screens/DemoResultsScreen/DemoResultsScreen.js";
import SelectAssessmentLangScreen from "../screens/SelectAssessmentLangScreen/SelectAssessmentLangScreen.js";
import VopaInputFieldsWrapper from "../screens/VopaInputFieldsScreen/VopaInputFieldWrapper.js";
import {
  getDeviceId,
  getGrade,
  getOrganisationId,
  getTeacherId,
} from "../utils/helper.js";

const VopaApp = ({ organisation = "demo", setSplash = () => {} }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslationSetup();
  const { appLanguage } = useParamValues();
  const [showVopaWelcomeScreen, setShowVopaWelcomeScreen] = useState(true);
  const [showNeedPermissionsScreen, setShowNeedPermissionsScreen] =
    useState(false);
  const [showVopaAboutScreen, setShowVopaAboutScreen] = useState(false);
  const [showVopaInstructionScreen, setShowVopaInstructionScreen] =
    useState(false);
  const [showVopaInputFieldsScreen, setShowVopaInputFieldsScreen] =
    useState(false);
  const [showAssessmentLangScreen, setShowAssessmentLangScreen] =
    useState(false);
  const [hideSlider, setHideSlider] = useState(false);
  const [hideArrow, setHideArrow] = useState(false);
  const [showSplashLoadingScreen, setShowSplashLoadingScreen] = useState(false);
  const [showOrfTestScreen, setShowOrfTestScreen] = useState(false);
  const [showResultLoadingScreen, setShowResultLoadingScreen] = useState(false);
  const [showOrfResultScreen, setShowOrfResultScreen] = useState(false);
  const [audioBlobForAudioPlayer, setAudioBlobForAudioPlayer] = useState(null);
  const [paraData, setParaData] = useState("");
  const [processedData, setProcessedData] = useState(null);
  const [orfResultStatus, setOrfResultStatus] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [audioPermissionStatus, setAudioPermissionStatus] = useState(null);
  const [locationPermissionStatus, setLocationPermissionStatus] =
    useState(null);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isAudioUploading, setAudioUploading] = useState(false);
  const [uploadAudioStatus, setUploadAudioStatus] = useState(null);
  const [studentName, setStudentName] = useState("");
  const [classNum, setClassNum] = useState("");
  const [schoolUdise, setSchoolUdise] = useState("");
  const [medium, setMedium] = useState("");
  const [profession, setProfession] = useState("");
  const [assessmentLang, setAssessmentLang] = useState(null);
  const [studentId, setStudentId] = useState("");

  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [instructorName, setInstructorName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [readingLevel, setReadingLevel] = useState("");
  const [requestBody, setRequestBody] = useState({});
  const [feedbackResponse, setFeedbackResponse] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem("app_language")) {
      i18n.changeLanguage(sessionStorage.getItem("app_language"));
    } else {
      i18n.changeLanguage(appLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLanguage]);

  useEffect(() => {
    if (
      paraData !== "" &&
      (organisation !== "pratham_demo_1" || feedbackResponse !== null)
    ) {
      setShowSplashLoadingScreen(false);
      setShowOrfTestScreen(true);
    }
  }, [paraData, feedbackResponse]);

  useEffect(() => {
    if (processedData != null) {
      setShowResultLoadingScreen(false);
      setShowOrfTestScreen(false);
      setShowOrfResultScreen(true);
    }
  }, [processedData]);

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "prompt") {
        setLocationPermissionStatus("PROMPT");
      } else if (result.state === "granted") {
        setLocationPermissionStatus("GRANTED");
      } else if (result.state === "denied") {
        setLocationPermissionStatus("DENIED");
      }
    });
    navigator.permissions.query({ name: "microphone" }).then((result) => {
      if (result.state === "prompt") {
        setAudioPermissionStatus("PROMPT");
      } else if (result.state === "granted") {
        setAudioPermissionStatus("GRANTED");
      } else if (result.state === "denied") {
        setAudioPermissionStatus("DENIED");
      }
    });
  }, []);

  return (
    <div>
      {showVopaWelcomeScreen && (
        <VopaWelcomeScreen
          showVopaWelcomeScreen={showVopaWelcomeScreen}
          setShowVopaWelcomeScreen={setShowVopaWelcomeScreen}
          showNeedPermissionsScreen={showNeedPermissionsScreen}
          setShowNeedPermissionsScreen={setShowNeedPermissionsScreen}
          showVopaAboutScreen={showVopaAboutScreen}
          setShowVopaAboutScreen={setShowVopaAboutScreen}
          audioPermissionStatus={audioPermissionStatus}
          setAudioPermissionStatus={setAudioPermissionStatus}
          locationPermissionStatus={locationPermissionStatus}
          setLocationPermissionStatus={setLocationPermissionStatus}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          showVopaInstructionScreen={showVopaInstructionScreen}
          setShowVopaInstructionScreen={setShowVopaInstructionScreen}
        />
      )}
      {showNeedPermissionsScreen &&
        (locationPermissionStatus !== "GRANTED" ||
          audioPermissionStatus !== "GRANTED") && (
          <NeedPermissionsScreen
            showNeedPermissionsScreen={showNeedPermissionsScreen}
            setShowNeedPermissionsScreen={setShowNeedPermissionsScreen}
            showVopaAboutScreen={showVopaAboutScreen}
            setShowVopaAboutScreen={setShowVopaAboutScreen}
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
            showVopaInstructionScreen={showVopaInstructionScreen}
            setShowVopaInstructionScreen={setShowVopaInstructionScreen}
          />
        )}

      {/* {showVopaAboutScreen && (
        <VopaAboutScreen
          showVopaAboutScreen={showVopaAboutScreen}
          setShowVopaAboutScreen={setShowVopaAboutScreen}
          showVopaInstructionScreen={showVopaInstructionScreen}
          setShowVopaInstructionScreen={setShowVopaInstructionScreen}
        />
      )} */}

      {showVopaInstructionScreen && (
        <VopaInstructionScreen
          showVopaInstructionScreen={showVopaInstructionScreen}
          setShowVopaInstructionScreen={setShowVopaInstructionScreen}
          showVopaInputFieldsScreen={showVopaInputFieldsScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
        />
      )}
      {showVopaInputFieldsScreen && (
        <VopaInputFieldsWrapper
          name={studentName}
          setName={setStudentName}
          classNum={classNum}
          setClassNum={setClassNum}
          schoolUdise={schoolUdise}
          setSchoolUdise={setSchoolUdise}
          medium={medium}
          setMedium={setMedium}
          profession={profession}
          setProfession={setProfession}
          showVopaInputFieldsScreen={showVopaInputFieldsScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          setShowAssessmentLangScreen={setShowAssessmentLangScreen}
          setStudentId={setStudentId}
          gender={gender}
          setGender={setGender}
          age={age}
          setAge={setAge}
          instructorName={instructorName}
          setInstructorName={setInstructorName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          readingLevel={readingLevel}
          setReadingLevel={setReadingLevel}
          setRequestBody={setRequestBody}
          organisation={organisation}
        />
      )}
      {showAssessmentLangScreen && (
        <SelectAssessmentLangScreen
          setShowAssessmentLangScreen={setShowAssessmentLangScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          assessmentLang={assessmentLang}
          setAssessmentLang={setAssessmentLang}
        />
      )}

      {showSplashLoadingScreen && (
        <EnterOrfApplicationLoadingScreen
          setParaData={setParaData}
          studentName={studentName}
          setStudentName={setStudentName}
          showVopaInputFieldsScreen={showVopaInputFieldsScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          showSplashLoadingScreen={showSplashLoadingScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          selectedLaguage={medium}
          setSelectedLaguage={setMedium}
          profession={profession}
          setProfession={setProfession}
          assessmentLang={assessmentLang}
          setFeedbackResponse={setFeedbackResponse}
          organisation={organisation}
          feedbackData={{
            feedback: {
              instructor_name: instructorName,
              instructor_phone_number: phoneNumber,
              gender: gender,
              age: age,
              reading_level: readingLevel,
            },
          }}
          requestBody={{
            student_id: studentId,
            teacher_id: getTeacherId(organisation),
            lang: assessmentLang.value,
            grade: getGrade(organisation, classNum),
            organisation_id: getOrganisationId(organisation),
            device_id: getDeviceId(organisation),
            school_id: schoolUdise,
            lat: latitude,
            long: longitude,
            student_grade: classNum,
          }}
        />
      )}
      {showOrfTestScreen && !showSplashLoadingScreen && (
        <OrfTestScreen
          hideArrow={hideArrow}
          setHideArrow={setHideArrow}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          paragraph={paraData["paragraph"]}
          showOrfResultScreen={showOrfResultScreen}
          setShowOrfResultScreen={setShowOrfResultScreen}
          processedData={processedData}
          setProcessedData={setProcessedData}
          assessmentId={paraData["id"]}
          showHomeScreen={showVopaInputFieldsScreen}
          setShowHomeScreen={setShowVopaInputFieldsScreen}
          studentName={studentName}
          setStudentName={setStudentName}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          selectedLaguage={medium}
          setSelectedLaguage={setMedium}
          classNum={classNum}
          setClassNum={setClassNum}
          schoolUdise={schoolUdise}
          setSchoolUdise={setSchoolUdise}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          isAudioUploading={isAudioUploading}
          setAudioUploading={setAudioUploading}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          setProfession={setProfession}
        />
      )}
      {showResultLoadingScreen && (
        <ResultLoadingScreen
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          showHomeScreen={showVopaInputFieldsScreen}
          setShowHomeScreen={setShowVopaInputFieldsScreen}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          isAudioUploading={isAudioUploading}
          setAudioUploading={setAudioUploading}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          setName={setStudentName}
          setProfession={setProfession}
          setSchoolUdise={setSchoolUdise}
          setClassNum={setClassNum}
        />
      )}
      {showOrfResultScreen &&
        (organisation === "vopa" ? (
          <OrfResultScreen
            heading={t("student_orf_result", {
              firstName: studentName.split(" ")[0],
            })}
            lastCwpm={0}
            audioBlobForAudioPlayer={audioBlobForAudioPlayer}
            setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
            processedData={processedData}
            showOrfResultScreen={showOrfResultScreen}
            setShowOrfResultScreen={setShowOrfResultScreen}
            showHomeScreen={showVopaInputFieldsScreen}
            setShowHomeScreen={setShowVopaInputFieldsScreen}
            hideSlider={hideSlider}
            setHideSlider={setHideSlider}
            studentName={studentName}
            setStudentName={setStudentName}
            selectedLaguage={medium}
            setSelectedLaguage={setMedium}
            classNum={classNum}
            setClassNum={setClassNum}
            schoolUdise={schoolUdise}
            setSchoolUdise={setSchoolUdise}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            isAudioUploading={isAudioUploading}
            setAudioUploading={setAudioUploading}
            uploadAudioStatus={uploadAudioStatus}
            setUploadAudioStatus={setUploadAudioStatus}
          />
        ) : (
          <DemoResultsScreen
            name={studentName}
            classNum={classNum}
            processedData={processedData}
            audioBlobForAudioPlayer={audioBlobForAudioPlayer}
            setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
            setSplash={setSplash}
            setShowOrfResultScreen={setShowOrfResultScreen}
            setShowAssessmentLangScreen={setShowAssessmentLangScreen}
            setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
            setHideSlider={setHideSlider}
            setOrfResultStatus={setOrfResultStatus}
            setUploadAudioStatus={setUploadAudioStatus}
            setAudioUploading={setAudioUploading}
            setSaveClicked={setSaveClicked}
            setName={setStudentName}
            setClassNum={setClassNum}
            setProfession={setProfession}
            setSchoolUdise={setSchoolUdise}
            setGender={setGender}
            setAge={setAge}
            setInstructorName={setInstructorName}
            setPhoneNumber={setPhoneNumber}
            setReadingLevel={setReadingLevel}
          />
        ))}
    </div>
  );
};

export default VopaApp;
